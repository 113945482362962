import { createReducer, on } from '@ngrx/store'
import { routerNavigationAction } from '@ngrx/router-store'

import { Customer } from '@boosterfuels/types/types-ts'
import { authActions } from './auth.actions'

export enum SignUpSteps {
  CHECK_STATUS = 'CHECK_STATUS',
  SIGN_UP = 'SIGN_UP',
  RESEND_INVITE = 'RESEND_INVITE',
}
export interface AuthState {
  login: {
    isSubmitting: boolean
    isErrored: boolean
    message: string
  }
  forgotPassword: {
    isSubmitting: boolean
    isSubmitted: boolean
    isErrored: boolean
    message: string
    nRequests: number
  }
  resetPassword: {
    isSubmitting: boolean
    isSubmitted: boolean
    isErrored: boolean
    message: string
    isPasswordInputDisabled: boolean
  }
  signUp: {
    isSubmitting: boolean
    isSubmitted: boolean
    isErrored: boolean
    message: string
    step: SignUpSteps
    customer: Customer
  }
}

export const initialState: AuthState = {
  login: {
    isSubmitting: false,
    isErrored: false,
    message: '',
  },
  forgotPassword: {
    isSubmitting: false,
    isSubmitted: false,
    isErrored: false,
    message: '',
    nRequests: 0,
  },
  resetPassword: {
    isSubmitting: false,
    isSubmitted: false,
    isErrored: false,
    message: '',
    isPasswordInputDisabled: false,
  },
  signUp: {
    isSubmitting: false,
    isSubmitted: false,
    isErrored: false,
    message: '',
    step: SignUpSteps.CHECK_STATUS,
    customer: null,
  },
}

export const reducer = createReducer(
  initialState,

  on(routerNavigationAction, (state) => ({
    ...state,
    login: initialState.login,
  })),

  on(authActions.LOGIN, (state) => ({
    ...state,
    login: {
      ...state.login,
      isSubmitting: true,
      isErrored: false,
      message: '',
    },
    resetPassword: initialState.resetPassword,
    signUp: initialState.signUp,
  })),

  on(authActions.LOGIN_SUCCESS, (state) => ({
    ...state,
    login: {
      ...state.login,
      isSubmitting: false,
    },
  })),

  on(authActions.LOGIN_ERROR, (state) => ({
    ...state,
    login: {
      ...state.login,
      isSubmitting: false,
      isErrored: true,
      message: 'The email or password is incorrect. Please try again.',
    },
  })),

  on(authActions.SEND_PASSWORD_RESET_EMAIL, (state) => ({
    ...state,
    forgotPassword: {
      ...state.forgotPassword,
      isSubmitting: true,
      isErrored: false,
      isSubmitted: false,
    },
  })),

  on(authActions.SEND_PASSWORD_RESET_EMAIL_SUCCESS, (state) => ({
    ...state,
    forgotPassword: {
      ...state.forgotPassword,
      isSubmitting: false,
      isSubmitted: true,
      nRequests: state.forgotPassword.nRequests + 1,
    },
  })),

  on(authActions.SEND_PASSWORD_RESET_EMAIL_ERROR, (state, { payload }) => ({
    ...state,
    forgotPassword: {
      ...state.forgotPassword,
      isSubmitting: false,
      isErrored: true,
      message: payload,
    },
  })),

  on(authActions.RESET_PASSWORD, (state) => ({
    ...state,
    resetPassword: {
      ...state.resetPassword,
      isSubmitting: true,
      isSubmitted: false,
      isErrored: false,
      message: '',
      isPasswordInputDisabled: true,
    },
  })),

  on(authActions.RESET_PASSWORD_SUCCESS, (state) => ({
    ...state,
    resetPassword: {
      ...state.resetPassword,
      isSubmitting: false,
      isSubmitted: true,
      isErrored: false,
      message: '',
    },
  })),

  on(authActions.RESET_PASSWORD_ERROR, (state, { payload }) => ({
    ...state,
    resetPassword: {
      ...state.resetPassword,
      isSubmitting: false,
      isErrored: true,
      message: payload,
      isPasswordInputDisabled: payload !== 'PASSWORD_MATCH',
    },
  })),

  on(authActions.CHECK_SIGN_UP_STATUS, (state) => ({
    ...state,
    signUp: {
      ...state.signUp,
      isSubmitting: true,
      isErrored: false,
    },
  })),

  on(authActions.CHECK_SIGN_UP_STATUS_SUCCESS, (state, { payload }) => ({
    ...state,
    signUp: {
      ...state.signUp,
      isSubmitting: false,
      step: payload.email ? SignUpSteps.RESEND_INVITE : SignUpSteps.SIGN_UP,
      customer: payload.customer,
    },
  })),

  on(authActions.CHECK_SIGN_UP_STATUS_ERROR, (state, { payload }) => ({
    ...state,
    signUp: {
      ...state.signUp,
      isSubmitting: false,
      isErrored: true,
      message: payload,
    },
  })),

  on(authActions.SIGN_UP, (state) => ({
    ...state,
    signUp: {
      ...state.signUp,
      isSubmitting: true,
      isSubmitted: false,
    },
  })),

  on(authActions.SIGN_UP_SUCCESS, (state) => ({
    ...state,
    signUp: {
      ...state.signUp,
      isSubmitting: false,
      isSubmitted: true,
    },
  })),

  on(authActions.SIGN_UP_ERROR, (state) => ({
    ...state,
    signUp: {
      ...state.signUp,
      isSubmitting: false,
    },
  })),

  on(authActions.RESEND_INVITE_EMAIL, (state) => ({
    ...state,
    signUp: {
      ...state.signUp,
      isSubmitting: true,
      isErrored: false,
    },
  })),

  on(authActions.RESEND_INVITE_EMAIL_SUCCESS, (state) => ({
    ...state,
    signUp: {
      ...state.signUp,
      isSubmitting: false,
      step: SignUpSteps.RESEND_INVITE,
    },
  })),

  on(authActions.RESEND_INVITE_EMAIL_ERROR, (state) => ({
    ...state,
    signUp: {
      ...state.signUp,
      isSubmitting: false,
      isErrored: true,
      step: SignUpSteps.RESEND_INVITE,
    },
  })),

  on(authActions.CLEAR_STATE, (state, { payload }) => ({
    ...state,
    [payload]: initialState[payload],
  }))
)
